import React from 'react'
import Diagnostic from '../components/Diagnostic'

const App = ({data}) => {
  return(
    <Diagnostic />
  )
}

export default App
