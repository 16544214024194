import React from 'react'
import Layout from './Layout'
import { postDiagnostics, checkAPIAccess } from '../utils/diagnostic'
import WaitLoader from './WaitLoader'

class Diagnostic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      diagnosticsSent: false,
      errorMessage: '',
      apiAccess: [],
      loading: true
    }
  }

  componentDidMount() {
    checkAPIAccess()
    .then((diagnosticInfo) => {
      this.setState({
        apiAccess: diagnosticInfo,
        loading: false
      })
    })
    .catch((err)=>{
      this.setState({
        errorMessage: err.message,
        loading: false
      })
    })
  }

  handleSubmitDiagnostics = () => {
    postDiagnostics(this.state.apiAccess)
    .then(function(){
      this.setState({
        diagnosticsSent: true
      })
    }.bind(this))
    .catch(err=>{
      this.setState({
        errorMessage: err.message
      })
    })
  }

  checkAPIAccess = () => {

  }

  render() {
    let apiMessages = this.state.apiAccess.map((item, i)=>{
      return(
        <p key={i}><strong>{item.label}</strong> {item.value.toString()}</p>
      )
    })
    let wait = null
    if(this.state.loading){
      wait = <WaitLoader message="Retreiving API STATUS..." />
    }
    return (
      <Layout wrapperClass='design-page'>
      <h3>Share your Diagnostic Information</h3>
      <p>Having trouble creating a design? Send your diagnostic information to our secure server to share with our developers.</p>
      {apiMessages}
      {wait}
      {!this.state.diagnosticsSent &&
        <button className="button" onClick={this.handleSubmitDiagnostics}>Send Info</button>
      }
      {this.state.diagnosticsSent &&
        <p>Thanks for sharing your information. We'll keep in touch.</p>
      }
      {this.state.errorMessage &&
        <>
        <div class="error">{this.state.errorMessage}</div>
        <p>Please make sure you are able to access our API at https://api.forms-surfaces.net on your network.</p>
        <p>You may need to contact your network administrator for assistance.</p>
        </>
      }

      </Layout>
    )
  }
}

export default Diagnostic
