import axios from 'axios'
import { Auth } from 'aws-amplify'
import { getUserAgent } from './misc'

const restPath = process.env.GATSBY_APIURL
const restApiPath = 'https://api.forms-surfaces.net/v1'

export const postDiagnostics = (statuses) => {
  return new Promise((resolve, reject) => {
    try{
      var postObject = {}
      Auth.currentSession()
        .then(function(session) {
          axios.get('https://geolocation-db.com/json/', {})
          .then(function(ipInfo){
            postObject.ipInfo = ipInfo.data
            postObject.userAgent = getUserAgent()
            postObject.statuses = JSON.stringify(statuses)
            axios
            .post(restPath + '/diagnostic', postObject,
            {headers: {'Content-Type': 'application/json',
                  'Authorization': session.idToken.jwtToken }})
            .then(function(design) {
              resolve(design.data)
            })
            .catch(function(err) {
              reject(err)
            })
          })
          .catch(function(err){
            reject(err)
          })
        })
        .catch(function(err) {
          axios.get('https://geolocation-db.com/json/', {})
          .then(function(ipInfo){
            postObject.ipInfo = ipInfo.data
            postObject.userAgent = getUserAgent()
            postObject.statuses = JSON.stringify(statuses)
            axios
            .post(restPath + '/diagnostic', postObject,
            {headers: {'Content-Type': 'application/json'}})
            .then(function(design) {
              resolve(design.data)
            })
            .catch(function(err) {
              reject(err)
            })
          })
          .catch(function(err){
            reject(err)
          })
        })
    }catch(err){
      reject(err)
    }
  })
}


export const checkAPIAccess = () => {
  return new Promise((resolve, reject) => {
    try{
      var returnStatuses = []
      returnStatuses.push({
        label: 'API_URL',
        value: restPath
      })
      axios.get(restPath+'/test', {})
      .then(function(returnData){
        if(returnData.data){
          returnStatuses.push({
            label: 'API_ACCESS',
            value: true
          })
        }else{
          returnStatuses.push({
            label: 'API_ACCESS',
            value: false
          })
        }
      Auth.currentSession()
        .then(function(session) {
            axios
              .get(restApiPath + '/profile',
                {headers: {'Content-Type': 'application/json',
                'Authorization': session.idToken.jwtToken }})
              .then(function(userInfo) {
                if(userInfo.data){
                  returnStatuses.push({
                    label: 'PROFILE_ACCESS',
                    value: true
                  })
                }else{
                  returnStatuses.push({
                    label: 'PROFILE_ACCESS',
                    value: false
                  })
                }
                resolve(returnStatuses)
              })
              .catch(function(err) {
                reject(err)
              })
          })
          .catch(function(err){
            returnStatuses.push({
              label: 'PROFILE_ACCESS',
              value: false
            })
            resolve(returnStatuses)
          })
        })
        .catch(function(err) {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}
